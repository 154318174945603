import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { EstimatedValuesMutationTypes } from '@/core/enums/EstimatedValues/Mutations';
import { EstimatedValuesActionTypes } from '@/core/enums/EstimatedValues/Actions';
import { Mutations as vehicleMutation } from '@/store/modules/Vehicle/mutations';
import { LinkTypes } from '@/core/enums/Links';
import axios from 'axios';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [EstimatedValuesActionTypes.GET_ESTIMATED_VALUES](
    { commit }: AugmentedActionContext,
    payload: number
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [EstimatedValuesActionTypes.GET_ESTIMATED_VALUES](context, payload: any) {
    try {
      const data = await axios.post(LinkTypes.GET_ESTEMATED_VALUE, payload);
      const defaultData = {old_max: 0, old_min: 0}
      context.commit(EstimatedValuesMutationTypes.SET_ESTIMATED_VALUES, data.data.data.value ? data.data.data.value : defaultData);
        const car_value = context.rootState.vehicles.vehicle.car_values && context.rootState.vehicles.vehicle.car_values === parseFloat(data.data.data.value.old_min) ? parseFloat(context.rootState.vehicles.vehicle.car_values.toString()) : parseFloat(data.data.data.value.old_min);
        context.rootState.vehicles.vehicle.car_values = data.data.data.value === null ? 0 : car_value;
    }
    catch(err) {
      console.log(err)
    }
  },
};
