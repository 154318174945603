import { MutationTree } from 'vuex';

import { Checkout, Payment } from '@/core/types/Checkouts';

import { State } from './state';
import { CheckoutMutationTypes } from '@/core/enums/Checkouts/Mutations';

export type Mutations<S = State> = {
  [CheckoutMutationTypes.SET_CHECKOUT](state: S, payload: Checkout): void;
  [CheckoutMutationTypes.SET_PAYMENT](state: S, payload: Payment): void;
  [CheckoutMutationTypes.SET_VAT](state: S, payload: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [CheckoutMutationTypes.SET_CHECKOUT](state: State, checkout: Checkout) {
    state.checkout = checkout;
  },
  [CheckoutMutationTypes.SET_PAYMENT](state: State, payment: Payment) {
    state.payment = payment;
  },
  [CheckoutMutationTypes.SET_VAT](state: State, vat: number) {
    state.vat = vat;
  },
  [CheckoutMutationTypes.SET_ADMIN_FEE](state: State, admin_fee: string) {
    state.admin_fee = admin_fee;
  },
  [CheckoutMutationTypes.SET_ADMIN_FEE_TPL](state: State, admin_fee_tpl: number) {
    state.admin_fee_tpl = parseFloat(admin_fee_tpl.toString());
  },
  [CheckoutMutationTypes.SET_VAT_COMPREHENSIVE](state: State, admin_fee_comprehensive: number) {
    state.admin_fee_comprehensive = parseFloat(admin_fee_comprehensive.toString());
  },
};
