<template>
    <header class="bg-white vechile-detail">
            <div class="container custom-container">
                <nav class="navbar navbar-expand-lg navbar-light p-0">
                    <a class="navbar-brand p-0" :href="homePage" v-if="language === 'ar'">
                        <img src="@/assets/images/arabic-logo.jpg" class="img-fluid d-none d-lg-inline" width="280">
                        <img src="@/assets/images/arabic-logo.jpg" class="img-fluid d-lg-none" width="229">
                    </a>
                    <a class="navbar-brand p-0" :href="homePage" v-else>
                        <img src="@/assets/images/logo/logo.svg" class="img-fluid d-none d-lg-inline" width="280">
                        <img src="@/assets/images/logo/mobile-logo.svg" class="img-fluid d-lg-none" width="229">
                    </a>
                    <div class="wizard-navigation health-wizard d-flex flex-wrap justify-content-center d-none d-lg-flex d-xl-flex">
                        <div :class="`wizard-navigation_item position-relative ${current.vehicle}`" @click="go('Home', current.vehicle)" style="cursor: pointer">
                            <div class="wizard-navigation_item--number">
                                1
                            </div>
                            <div class="wizard-navigation_item--title">
                                <p v-if="language === 'en'">{{ $t('vehi') }} <br />
                                    {{ $t('details') }}</p>
                                <p v-if="language === 'ar'">{{ $t('details') }}  <br />
                                    {{ $t('vehi') }}</p>
                            </div>
                        </div>
                        
                        <div :class="`wizard-navigation_item position-relative ${current.compare}`" @click="go('QoutesDetails', current.compare)" style="cursor: pointer">
                            <div class="wizard-navigation_item--number">
                                2
                            </div>
                            <div class="wizard-navigation_item--title">
                                <p v-if="language === 'en'">{{ $t('comp') }} <br />
                                    {{ $t('quotes') }}</p>
                                <p v-if="language === 'ar'">{{ $t('quotes_header') }} <br />
                                    {{ $t('comp_header') }}</p>
                            </div>
                        </div>
                        <div :class="`wizard-navigation_item position-relative ${current.cover}`" @click="go('SecureChecout', current.cover)" style="cursor: pointer">
                            <div class="wizard-navigation_item--number">
                                <span class="icon-Tickmark"></span>
                            </div>
                            <div class="wizard-navigation_item--title">
                                <p v-if="language === 'en'">{{ $t('your') }} <br />
                                    {{ $t('cov') }}</p>
                                <p v-if="language === 'ar'">{{ $t('your_cover') }} </p>
                            </div>
                        </div>
                    </div>

                    <div class="header-right d-flex flex-wrap align-items-center header-right-custom">
                        <div class="header-right__contact d-flex flex-wrap align-items-center">
                            <a href="https://api.whatsapp.com/send?phone=96171396654" class="header-right__contact__item position-relative whats-app" target="_blank">
                                <div class="header-icon">
                                    <img src="@/assets/images/icons/whatsapp.svg" class="img-fluid">
                                </div>
                            </a>

                            <a href="tel:01481565" class="header-right__contact__item position-relative call-app">
                                <div class="header-icon">
                                    <img src="@/assets/images/icons/call.svg" class="img-fluid">
                                </div>
                                <div class="header-number" style="direction: ltr">
                                    <p>01481565</p>
                                </div>
                            </a>
                        </div>


                    </div>

                </nav>

            </div>
        </header>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from 'vue';

import { store } from '@/store';
import router from '@/router';
import Language from '@/core/services/car/Language';
import Manufacturers from '@/core/services/car/Manufacturers';
import setVehicle from "@/core/services/car/Vehicles";
import Models from '@/core/services/car/Models';
import TrimLevels from '@/core/services/car/TrimLevels';
import EstimatedValues from '@/core/services/car/EstimatedValues';

export default defineComponent({
    created() {
        const html = document.documentElement // returns the html tag
        html.setAttribute('lang', window.localStorage.getItem('language'))
        html.setAttribute('dir', window.localStorage.getItem('language') == 'en' ? 'ltr' : 'rtl')
    },
    
    setup() {
        const current = computed(() => {
            return store.state.steppers.stepper
        })

        const vd = computed(() => {
            return store.state.vehicles.vehicle;
        })

        const go = async(val, y) => {
            let uuid = window.localStorage.getItem(uid(val));
            let source = window.localStorage.getItem('sources')
            const customer_detatils = JSON.parse(window.localStorage.getItem('customer_detatils'))

            let url = '/vehicle-details'
            switch(val) {
                case 'DriverDetails':
                    url = '/driver-details' 
                    break
                case 'QoutesDetails':
                    url = '/result-listing' 
                    break
                case 'SecureChecout':
                    url = '/secure-checkout' 
                    break
                default:
                    url = customer_detatils && customer_detatils.customer_source_url ? customer_detatils.customer_source_url : '/vehicle-details'
                    break;
            }
            url = (process.env.VUE_APP_FOLDER !== 'undefined'  && process.env.VUE_APP_FOLDER.length > 0) ? `/${process.env.VUE_APP_FOLDER}`+url : url;
            vd.value.is_wizard = true
            
            if(y) {
                if(customer_detatils && url !== customer_detatils.customer_source_url) {
                   window.location.href = url
                }
                if(uuid) {
                    if(router.currentRoute.value.name === 'VehicleDetails') {
                        return window.location.href = customer_detatils.customer_source_url
                    } else if(router.currentRoute.value.name === 'DriverDetails') {
                        return window.location.href = `${url}&uuid=${uuid}${source}`
                    } else {
                        const passVal = url.includes('?') ? '&' : '?'

                        let path = url
                        if(!url.includes('uuid')) path = `${url}${passVal}uuid=${uuid}`

                        if(source) path += `${source}`
                        if(customer_detatils && customer_detatils.sid && !url.includes('sid')) path += `&sid=${customer_detatils.sid}`
                        
                        return window.location.href = path
                    }
                } else {
                    let urli = url
                    if(source) urli = `${url}?${source}`
                    if(!urli.includes('sid')) {
                        const passVal = urli.includes('?') ? '&' : '?'
                        urli = `${urli}${passVal}sid=${customer_detatils.sid}`
                    }
                    return window.location.href = urli
                }
            }

            const myStorage = window.localStorage.getItem("customer_detatils");
            if (myStorage) {
                const vehicle = JSON.parse(myStorage);
                setVehicle.setVehicle(vehicle);
                if(vehicle.car_year) {
                    Manufacturers.getManufacturers({year: vehicle.car_year});
                }
                if(vehicle.model_id) {
                Models.getModelsByManufacturerId({year: vehicle.car_year, manufacturer_id: vehicle.manufacturer_id});
                }
                if(vehicle.model_id) {
                TrimLevels.getTrimLevelsByYearAndModelId({
                    model_id: vehicle.model_id,
                    year: vehicle.car_year,
                });
                }
                // if(vehicle.trim_level_id) {
                // EstimatedValues.getEstimatedValuesByYearManufacturerIdModelIdTrimLevelId(
                //     {
                //     car_year: vehicle.car_year,
                //     manufacturer_id: vehicle.manufacturer_id,
                //     model_id: vehicle.model_id,
                //     trim_level_id: vehicle.trim_level_id,
                //     }
                // );
                // }
            }
        }

        const uid = (e) => {
            switch(e) {
                case 'Home': return 'fall';
                case 'DriverDetails': return 'fall_back';
                case 'QoutesDetails': return 'fall_back';
                default: return 'fall';
            }
        }

        const language = computed(() => {
            return store.state.lang.lang
        })

        const changeLanguage = (e) => {
            Language.setLanguage(e);
            window.location.reload();
        }

        const homePage = process.env.VUE_APP_PAYMENT_GATEWAY_URL + `/` + language.value;

        return {
            homePage,
            current,
            language,
            vd,
            go,
            changeLanguage
        }
    }    
})

</script>