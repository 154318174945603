import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { Mutations as manufacturerMutation } from '@/store/modules/Manufacturers/mutations';
import { Mutations as modelMutation } from '@/store/modules/Models/mutations';
import { Mutations as trimLevelMutation } from '@/store/modules/TrimLevels/mutations';
import { Mutations as estimatedValueMutation } from '@/store/modules/EsitmatedValues/mutations';
import { QoutesMutationTypes } from '@/core/enums/Qoutes/Mutations';
import { ManufacturersMutationTypes } from '@/core/enums/Manufacturers/Mutations';
import { ModelsMutationTypes } from '@/core/enums/Models/Mutations';
import { TrimLevelsMutationTypes } from '@/core/enums/TrimLevels/Mutations';
import { EstimatedValuesMutationTypes } from '@/core/enums/EstimatedValues/Mutations';
import { QoutesActionTypes } from '@/core/enums/Qoutes/Actions';
import { LinkTypes } from '@/core/enums/Links';
import axios from 'axios';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  commit<K extends keyof manufacturerMutation>(
    key: K,
    payload: Parameters<manufacturerMutation[K]>[1],
  ): ReturnType<manufacturerMutation[K]>;
  commit<K extends keyof modelMutation>(
    key: K,
    payload: Parameters<modelMutation[K]>[1],
  ): ReturnType<modelMutation[K]>;
  commit<K extends keyof trimLevelMutation>(
    key: K,
    payload: Parameters<trimLevelMutation[K]>[1],
  ): ReturnType<trimLevelMutation[K]>;
  commit<K extends keyof estimatedValueMutation>(
    key: K,
    payload: Parameters<estimatedValueMutation[K]>[1],
  ): ReturnType<estimatedValueMutation[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [QoutesActionTypes.GET_COMPREHENSIVE_AND_THIRD_PARTY](
    { commit }: AugmentedActionContext,
    payload: number
  ): any;
  [QoutesActionTypes.GET_COMPARE_QOUTES](
    { commit }: AugmentedActionContext,
    payload: Object
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [QoutesActionTypes.GET_COMPREHENSIVE_AND_THIRD_PARTY]({ commit }, payload : any) {
    commit(QoutesMutationTypes.SET_IS_LOADING, true);
    try {
      const thirdParty = await axios.post(LinkTypes.GET_LEBANON_THIRD_PARTY_POLICIES, payload);
      const comprehensive = await axios.post(LinkTypes.GET_LEBANON_ALL_RISK_POLICIES, payload);
      const comprehensivefiftyfifty = await axios.post(LinkTypes.GET_LEBANON_ALL_RISK_FIFTYFIFTY_POLICIES, payload);
      const totalLoss = await axios.post(LinkTypes.GET_LEBANON_TOTAL_LOSS_POLICIES, payload);
      console.log(comprehensive)
      // const thirdParty = await axios.post(LinkTypes.GET_THIRD_PARTY, payload);
      const defValue = await axios.post(LinkTypes.VEHICLE_DRIVER_DETAILS, payload);
      
      const customer_detatils = await defValue.data.data.customer_detatils;

      commit(QoutesMutationTypes.SET_COMPREHENSIVES, comprehensive.data.data.quotes);
      commit(QoutesMutationTypes.SET_COMPREHENSIVES_FIFTYFIFTY, comprehensivefiftyfifty.data.data.quotes);
      commit(QoutesMutationTypes.SET_THIRD_PARTIES, thirdParty.data.data.quotes);
      commit(QoutesMutationTypes.SET_TOTAL_LOSS, totalLoss.data.data.quotes);
      commit(QoutesMutationTypes.SET_IS_LOADING, false);

      window.localStorage.setItem('driver_details', JSON.stringify(defValue.data.data.driver_details))
      window.localStorage.setItem('customer_detatils', JSON.stringify(customer_detatils))
      const fall_back = window.localStorage.getItem('fall_back');
      if(!fall_back) {
        window.localStorage.setItem('fall_back', defValue.data.data.fall_back)
      }


      if(customer_detatils) {
        if(customer_detatils.car_year) {
          const Manufacturers = await axios.post(LinkTypes.GET_MANUFACTURERS, {year: customer_detatils.car_year});
          commit(ManufacturersMutationTypes.SET_MANUFACTURERS, Manufacturers.data.data.manufacturers);
        }
        if(customer_detatils.manufacturer_id) {
          const Models = await axios.post(LinkTypes.GET_MODELS, {year: customer_detatils.car_year, 
            manufacturer_id: customer_detatils.manufacturer_id});
          commit(ModelsMutationTypes.SET_MODELS, Models.data.data.models);
        }
      //   if(customer_detatils.model_id) {
      //     const trimLevel = await axios.post(LinkTypes.GET_TRIM_LEVELS, {
      //       model_id: customer_detatils.model_id,
      //       year: customer_detatils.car_year,
      //     });
      //     commit(TrimLevelsMutationTypes.SET_TRIM_LEVELS, trimLevel.data.data.trim_level);
      //   }
      //   if(customer_detatils.trim_level_id) {
      //     const estimatedValue = await axios.post(LinkTypes.GET_ESTEMATED_VALUE, {
      //       car_year: customer_detatils.car_year,
      //       manufacturer_id: customer_detatils.manufacturer_id,
      //       model_id: customer_detatils.model_id,
      //       trim_level_id: customer_detatils.trim_level_id,
      //     });
      //     commit(EstimatedValuesMutationTypes.SET_ESTIMATED_VALUES, estimatedValue.data.data.value);
      //   }
      }
      // commit(QoutesMutationTypes.SET_IS_LOADING, false);

      // if(comprehensive.data.data.quotes.length === 0 && thirdParty.data.data.tpl_quotes.length === 0) {
      //   commit(QoutesMutationTypes.SET_NO_RECORD, false)
      // } else {
      //   commit(QoutesMutationTypes.SET_NO_RECORD, true)
      // }
      return comprehensive
    }
    catch(err) {
      console.log(err)
      commit(QoutesMutationTypes.SET_IS_LOADING, false);
      return err
    }
  },

  async [QoutesActionTypes.GET_COMPARE_QOUTES]({ commit }, payload : any) {
    const compareData = await axios.post(LinkTypes.GET_COMPARE_DATA, payload);
    try {
      commit(QoutesMutationTypes.SET_COMPARED_DATA, compareData.data.data.merge);
      
    }
    catch(err) {
      console.log(err)
    }
  },

  async [QoutesActionTypes.SEND_CHEAPEST_QUOTES](context, payload: any) {
    
    try {
      const data = await axios.post(LinkTypes.POST_CHEAPEST_QUOTES, payload);
      return data;
      
    }
    catch(err) {
      console.log(err)
    }

  },


  async [QoutesActionTypes.GET_BIKE_COMPARE_QUOTES]({ commit }, payload : any) {
    //commit(QoutesMutationTypes.SET_IS_LOADING, true);
    try {
      const thirdParty = await axios.post(LinkTypes.GET_BIKE_COMPARE_QUOTES, payload);
      const defValue = await axios.post(LinkTypes.VEHICLE_DRIVER_DETAILS, payload);
      const customer_detatils = await defValue.data.data.customer_detatils;

      commit(QoutesMutationTypes.SET_THIRD_PARTIES, thirdParty.data.data.tpl_quotes);
      
      if(thirdParty.data.data.tpl_quotes.length === 0) {
        commit(QoutesMutationTypes.SET_NO_RECORD, false)
      }

      //window.localStorage.setItem('driver_details', JSON.stringify(defValue.data.data.driver_details))
      //window.localStorage.setItem('customer_detatils', JSON.stringify(customer_detatils))
      // const fall_back = window.localStorage.getItem('fall_back');
      // if(!fall_back) {
      //   window.localStorage.setItem('fall_back', defValue.data.data.fall_back)
      // }

      commit(QoutesMutationTypes.SET_IS_LOADING, false);
      return customer_detatils
    }
    catch(err) {
      console.log(err)
      commit(QoutesMutationTypes.SET_IS_LOADING, false);
      return err
    }
  },


  async [QoutesActionTypes.SEND_BIKE_CHEAPEST_QUOTES](context, payload: any) {
    
    try {
      const data = await axios.post(LinkTypes.POST_BIKE_CHEAPEST_QUOTES, payload);
      return data;
      
    }
    catch(err) {
      console.log(err)
    }

  },

  


};
