import { App } from "vue";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import selectDateV2 from '@/components/reusables/SelectDateV2.vue'
import PageLoader from '@/components/reusables/PageLoader.vue';
import hdiPopup from '@/components/reusables/HdiPopup.vue';

export function initComponent(app: App<Element>) {

    const data = [
        { key: 'vc-date-picker', component: selectDateV2 },
        { key: 'h-page-loader', component: PageLoader },
        { key: 'hdi-popup', component: hdiPopup },
    ];

    data.forEach(element => {
        app.component(element.key, element.component);
    });

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
}