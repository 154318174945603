import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { CouponMutationTypes } from '@/core/enums/Coupons/Mutations';
import { CouponActionTypes } from '@/core/enums/Coupons/Actions';
import { LinkTypes } from '@/core/enums/Links';
import axios from 'axios';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [CouponActionTypes.POST_COUPON](
    { commit }: AugmentedActionContext,
    payload: any
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
   [CouponActionTypes.POST_COUPON]({ commit }, payload: any) {
    commit(CouponMutationTypes.SET_COUPON_SUCCESS, null);
    commit(CouponMutationTypes.SET_COUPON_ERROR, null);

    return new Promise((res, rej) => {
       axios.post(LinkTypes.POST_COUPON, payload)
        .then((result) => {
          res(result.data)
          window.localStorage.setItem('coupon', JSON.stringify(result.data));
          commit(CouponMutationTypes.SET_COUPON_SUCCESS, result.data);
        })
        .catch((error) => {
          rej(error)
          window.localStorage.removeItem('coupon');
          commit(CouponMutationTypes.SET_COUPON_ERROR, error.response.data.message);
        });
    })
  },

};
