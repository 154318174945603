// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum CheckoutMutationTypes {
    SET_CHECKOUT = 'SET_CHECKOUT',
    SET_PAYMENT = 'SET_PAYMENT',
    SET_VAT = 'SET_VAT',
    SET_ADMIN_FEE = 'SET_ADMIN_FEE',
    SET_ADMIN_FEE_TPL = 'SET_ADMIN_FEE_TPL',
    SET_VAT_COMPREHENSIVE = 'SET_VAT_COMPREHENSIVE',
  }
  