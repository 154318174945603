import { Checkout, Payment } from '@/core/types/Checkouts';


export type State = {
  checkout: Checkout | null;
  payment: Payment | null;
  vat: number,
  admin_fee: string,
  admin_fee_tpl: number,
  admin_fee_comprehensive: number,
}

export const state: State = {
  checkout: {
    uuid: "",
    url: "",
    coupon: "",
    policy: {},
    quote: {}

  },
  payment: null,
  vat: 0,
  admin_fee: '',
  admin_fee_tpl: 0,
  admin_fee_comprehensive: 0
};