import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { DriversMutationTypes } from '@/core/enums/Drivers/Mutations';
import { DriversActionTypes } from '@/core/enums/Drivers/Actions';
import axios from 'axios';
import { LinkTypes } from '@/core/enums/Links';
import router from '@/router';
import Qoute from '@/core/services/car/Qoutes';
import moment from 'moment';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [DriversActionTypes.GET_DRIVER](
    { commit }: AugmentedActionContext,
  ): Promise<boolean>;

  [DriversActionTypes.POST_DRIVER](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;

}

export const actions: ActionTree<State, RootState> & Actions = {
  async [DriversActionTypes.GET_DRIVER]({ commit }) {
    return new Promise(() => {
      const dd = localStorage.getItem('driver_details') ? JSON.parse(localStorage.getItem('driver_details') || '') : null;
        
      const todayMonth = new Date().getMonth() + 1;
      const todayDate = new Date().getDate();
      const todayYear = new Date().getFullYear();
      const car_year = JSON.parse(localStorage.getItem('customer_detatils') || '').car_year;
      const driver = {
          
          uuid: window.localStorage.getItem('fall_back') || '',
          sid: '',
          dob: dd ? dd.dob : null,
          // dob_month: dd ? dd.dob_month : null,
          // dob_day: dd ? dd.dob_day : null,
          // dob_year: dd ? dd.dob_year : null,
          car_value: JSON.parse(localStorage.getItem('customer_detatils') || '').car_values || JSON.parse(localStorage.getItem('driver_details') || '').car_value,
          first_registration_date: dd ? dd.first_registration_date : `${car_year}-${todayMonth.toString().length == 1 ? `0${todayMonth}` : todayMonth}-${todayDate.toString().length == 1 ? `0${todayDate}` : todayDate}`,
          // first_registration_date_month: dd ? dd.first_registration_date_month : todayMonth,
          // first_registration_date_day: dd ? dd.first_registration_date_day : todayDate,
          // first_registration_date_year: dd ? dd.first_registration_date_year : car_year,
          nationality: dd ? dd.nationality : null,
          first_driving_license_country: dd ? dd.first_driving_license_country : null,
          driving_experience: dd ? dd.driving_experience : null,
          uae_driving_experience: dd ? dd.uae_driving_experience : null,
          policy_start_date: dd ? dd.policy_start_date : `${todayYear}-${todayMonth.toString().length == 1 ? `0${todayMonth}` : todayMonth}-${todayDate.toString().length == 1 ? `0${todayDate}` : todayDate}`,
          // policy_start_date_month: dd ? dd.policy_start_date_month :   todayMonth,
          // policy_start_date_day: dd ? dd.policy_start_date_day : todayDate,
          // policy_start_date_year: dd ? dd.policy_start_date_year : todayYear,
          claims: dd ? dd.claims : 0,
          claimed_insurance: dd ? dd.claimed_insurance : 2,
          no_claim_certificate: dd ? dd.no_claim_certificate : 1,
          gcc_specification: dd ? dd.gcc_specification : 1,
          is_new: JSON.parse(localStorage.getItem('customer_detatils') || '').is_new,
          personal_use: dd ? dd.personal_use : 1,
          current_policy_active: dd ? dd.current_policy_active : 1,
          fully_comprehensive: dd ? dd.fully_comprehensive : 1,
          third_party_liability: dd ? dd.third_party_liability : 2,
          existing_policy_expired: dd ? dd.existing_policy_expired : 2
        };
        driver.dob  = moment(driver.dob,'YYYY-MM-DD').format('YYYY-MM-DD');
        driver.first_registration_date  = moment(driver.first_registration_date,'YYYY-MM-DD').format('YYYY-MM-DD');
        driver.policy_start_date  = moment(driver.policy_start_date,'YYYY-MM-DD').format('YYYY-MM-DD');
        commit(DriversMutationTypes.SET_DRIVER, driver);
        return true;

    });
  },

  async [DriversActionTypes.POST_DRIVER](context, payload: any) {
    const source = window.localStorage.getItem('sources')
    context.commit(DriversMutationTypes.SET_DRIVER_LOADING_PAGE, true);
    try {
      const { data } = await axios.post(LinkTypes.POST_DRIVER_DETAILS, payload)
      window.localStorage.setItem('fall_back_dd', data.data.data.uuid);
      window.localStorage.setItem('driver_details',JSON.stringify(payload));
      // router.push({name: 'QoutesDetails', query: {uuid: data.data.data.uuid}});
      const result = await Qoute.sendCheapestQuotes({
        uuid:data.data.data.uuid,
        lang: window.localStorage.getItem('language')
      });
      window.location.href = `/result-listing?uuid=${data.data.data.uuid}${source}`
      context.commit(DriversMutationTypes.SET_DRIVER_LOADING_PAGE, false);
    

      return data
      
    } catch (err) {
      console.log(err)
    }
  }
};
