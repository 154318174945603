import { store } from "@/store";
import { App } from "vue";
import { CheckoutsActionTypes } from "@/core/enums/Checkouts/Actions";
import { Checkout as check } from '@/core/types/Checkouts';

class Checkout{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Checkout.vueInstance = app;
    }

    public static async postCheckout(payload: check) {
        return await store
        .dispatch(CheckoutsActionTypes.POST_CHECKOUT, payload)
    }

    public static fetchPayfort(payload: number) {
        store
        .dispatch(CheckoutsActionTypes.GET_PAYFORT_FORM, payload)
    }

    public static async getVat() {
        return await store.dispatch(CheckoutsActionTypes.GET_VAT)
    }

}

export default Checkout;